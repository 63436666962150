import {
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
    ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Widget } from 'src/app/shared/classes/Widget';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IConfirmationModal } from 'src/app/shared/interfaces/modal/IConfirmationModal';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import {
    AssessmentAuditTypes,
    AssessmentCacheService
} from 'src/app/shared/services/cache/assessment-cache/assessment-cache.service';
import { GlobalDataService } from 'src/app/shared/services/global-data/global-data.service';
import { WidgetHttpService } from 'src/app/shared/services/http/widget-http/widget-http.service';
import { NotificationsService } from 'src/app/shared/services/notifications/notifications.service';
import { UserDataCacheService } from 'src/app/shared/services/user-data-cache/user-data-cache.service';
import { WidgetCreationService } from 'src/app/shared/services/widget-creation/widget-creation.service';
import { UploadEvidenceModalComponent } from '../upload-evidence-modal/upload-evidence-modal.component';
import { Helper } from './../../../../classes/Helper';
import { FrameworkStatus } from './../../../../enums/FrameworkStatus';
import { MetStatuses } from './../../../../enums/MetStatuses';
import { IWidgetUrlInfo } from './../../../../interfaces/widget/IWidgetUrlInfo';
import { WidgetCacheService } from './../../../../services/cache/widget-cache/widget-cache.service';
import { ModalService } from './../../../../services/modal/modal-service/modal.service';
import { IScoringAssessmentModal } from 'src/app/shared/interfaces/modal/IScoringAssessmentModal';
import { ScoringAssessmentModalComponent } from '../../scoring-assessment-modal/scoring-assessment-modal.component';

@Component({
    selector: 'app-assessment-overview',
    templateUrl: './assessment-overview.component.html',
    styleUrls: ['./assessment-overview.component.sass'],
})
export class AssessmentOverviewComponent implements OnInit {
    @Input() data: IWidgetUrlInfo;
    @Input() pageName: string;
    overviewData: any;
    assessorOverviewData: any;
    Tab = Tab;
    public AssessmentAuditTypes = AssessmentAuditTypes;
    Status = Status;
    metButtonInputs: IButtonGeneratorInput[];
    readySectionIcon: IIcon = {
        type: IconType.SVG_ASSETS,
        class: 'approved_icon_v2',
    };
    rejectedIcon: IIcon = {
        type: IconType.SVG_ASSETS,
        class: 'rejected_icon',
        extraClass: 'assessment_incomplete_icon',
    };
    approvedIcon: IIcon = {
        type: IconType.SVG_ASSETS,
        class: 'approved_icon',
    };
    incompleteSectionIcon: IIcon = {
        type: IconType.SVG,
        class: 'assessment_incomplete_icon',
        extraClass: 'svg-tick-icon-fill',
    };
    reviewSectionIcon: IIcon = {
        type: IconType.SVG_ASSETS,
        class: 'view_icon',
        extraClass: 'svg-tick-icon-fill',
    };
    reworkSectionIcon: IIcon = {
        type: IconType.SVG,
        class: 'rework_icon',
        extraClass: 'svg-tick-icon-fill',
    };
    notEvaluatedIcon: IIcon = {
        type: IconType.SVG_ASSETS,
        class: 'assessment_not_evaluated_icon',
    };
    downloadIcon: IIcon = {
        type: IconType.SVG_ASSETS,
        class: 'download_icon',
        extraClass: 'action-icon',
    };
    loader: boolean = false;
    spinnerLoader: IIcon = {
        type: IconType.SPINNERLOADER,
    };
    ButtonText = ButtonText;
    disableSubmitButton: boolean = false;
    disableActionButtons: boolean = false;
    enableReportGenInput: IFormGeneratorInput = null;
    enableReportFormRef: FormGroup;
    selectedTab = Tab.ASSESSE_OVERVIEW;
    sections: any;
    restrictedStatus: string[] = ['Marked For Review', 'Incomplete', 'Blank'];
    statusLoader: Map<string, boolean> = new Map();
    @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;
    frameworkStatus = FrameworkStatus;
    endAssessmentState: string[] = [
        this.frameworkStatus.APPROVED,
        this.frameworkStatus.REJECTED,
    ];
    subSections: Map<string, Map<string, []>> = new Map();
    subSectionsGroup: Map<string, any> = new Map();
    subSectionsForAssessor: Map<string, Map<string, []>> = new Map();
    subSectionsGroupForAssessor: Map<string, any> = new Map();
    showSubSections: Map<string, boolean> = new Map();
    @ViewChild('tabGroup', { static: false }) tabGroup;
    submitButtonGenInput: IButtonGeneratorInput;
    bulkActionButtonGenInput: IButtonGeneratorInput;
    showDownloadReportButton: boolean = false;
    downloadButtonGenInput: IButtonGeneratorInput;
    buttonInputs: IButtonGeneratorInput[];
    rejectButtonGenInput: IButtonGeneratorInput;
    saveButtonGenInput: IButtonGeneratorInput;
    approveButtonGenInput: IButtonGeneratorInput;
    isChecked: Map<string, Map<string, {}>> = new Map();
    enableBulkActionButton: boolean = false;
    @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
    totalSections: Object[];
    enableSummaryReport: boolean;
    enableDetailedReport: boolean;
    activeCard: Map<string, boolean> = new Map();
    restrictActionForStatus: string[] = [
        this.frameworkStatus.ISSUED,
        this.frameworkStatus.IN_PROGRESS,
    ];
    widgetRef;
    modalClose: Symbol;
    completedSections: any[];
    public isCSPScoring: boolean = this.assessmentCacheService.assessmentAuditType ===
            AssessmentAuditTypes.CSP_SCORING;
    public isScoringAudit: boolean =
            this.isCSPScoring ||
            this.assessmentCacheService.assessmentAuditType ===
                AssessmentAuditTypes.MS_SUPPORT;
    isJuniperAudit: boolean = this.assessmentCacheService.assessmentAuditType ===
            AssessmentAuditTypes.JUNIPER_AUDIT;
    sectionIcons: Map<string, string[]> = new Map();
    infoMessage: string = '';
    isAIAudit: boolean = this.assessmentCacheService.assessmentAuditType ===
            AssessmentAuditTypes.AI_HIDE_SHOW_DYNAMIC
    private saveAssessmentReportAccessResponse: SaveAssessmentReportAccessResponseType;

    constructor(
        public globalDataService: GlobalDataService,
        private widgetHttpService: WidgetHttpService,
        public userDataCacheService: UserDataCacheService,
        private notificationService: NotificationsService,
        private cdRef: ChangeDetectorRef,
        public assessmentCacheService: AssessmentCacheService,
        private widgetCacheService: WidgetCacheService,
        private widgetCreationService: WidgetCreationService,
        private modalService: ModalService
    ) {
        this.widgetRef = Widget;
        this.globalDataService.isFrameworkPage.next(false);
    }

    ngOnInit(): void {
        if (this.assessmentCacheService.createAssessmentSideMenuData) {
            this.globalDataService.createAssessmentOverview = true;
        }
        this.activeCard.set(this.Status.TOTAL_SECTION, true);
        const apiArgs: IHitApi = {
            url: ApiUrls.SAVE_ACCESS_REPORT_ASSESSMENT,
            input: {},
            requestType: RequestType.GET,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN,
            },
            function: (data) => {
                this.buttonInputs[0].disable =
                    data.canDownloadSummaryReport ||
                    this.userDataCacheService.isAssessorView
                        ? false
                        : true;
                this.buttonInputs[0].hoverText =
                    !data.canDownloadSummaryReport &&
                    !this.userDataCacheService.isAssessorView
                        ? 'Insufficient permission. Please contact Program Co-ordinator'
                        : '';
                this.buttonInputs[1].disable =
                    data.canDownloadDeatiledReport ||
                    this.userDataCacheService.isAssessorView
                        ? false
                        : true;
                this.buttonInputs[1].hoverText =
                    !data.canDownloadDeatiledReport &&
                    !this.userDataCacheService.isAssessorView
                        ? 'Insufficient permission. Please contact Program Co-ordinator'
                        : '';
                this.enableReportFormRef
                    ?.get('canDownloadSummaryReport')
                    .setValue(data?.canDownloadSummaryReport);
                this.enableReportFormRef
                    ?.get('canDownloadDeatiledReport')
                    .setValue(data?.canDownloadDeatiledReport);
            },
            errorFunction: (error) => {},
        };
        apiArgs.url = apiArgs.url.replace(
            '{assessment-id}',
            this.assessmentCacheService.assessmentId
        );
        new HitApi(
            apiArgs,
            this.widgetHttpService,
            this.widgetHttpService.ngZone
        ).hitApi();
        if (this.userDataCacheService.isAssessorView) {
            this.selectedTab = Tab.ASSESSOR_OVERVIEW;
        }
        // Added handling for ISV Audit. Note key to be recieved from BE
        if (this.data.response?.['id'] === '54bc5974-bddf-11eb-8529-0242ac130003' && this.data.response?.['note']) {
            this.infoMessage = this.data.response['note'];
        } else {
            this.infoMessage = '';
        }
        if (
            this.data &&
            this.data.response &&
            this.data.response['assesseOverview']
        ) {
            this.overviewData = this.data.response['assesseOverview'];
            this.sections = this.overviewData['overview']['sections'];
            this.totalSections = this.overviewData['overview']['sections'];
            this.handleSubSections(this.sections);
        }
        if (
            this.data &&
            this.data.response &&
            this.data.response['assessorOverview']
        ) {
            this.assessorOverviewData = this.data.response['assessorOverview'];
            this.sections = this.assessorOverviewData['overview']['sections'];
            this.totalSections =
                this.assessorOverviewData['overview']['sections'];
            this.handleSubSections(this.sections);
        }
        if (
            this.data &&
            this.data.response &&
            this.data.response['attributeName']
        ) {
            this.assessmentCacheService.assessmentName =
                this.data.response['attributeName'];
        }
        if (
            this.data &&
            this.data.response &&
            this.data.response['canDownloadReport']
        ) {
            this.showDownloadReportButton =
                this.data.response['canDownloadReport'];
        }
        if (
            this.data &&
            this.data.response &&
            this.data.response['assessorOverview']
        ) {
            this.assessorOverviewData = this.data.response['assessorOverview'];
            this.sections =
                this.selectedTab === Tab.ASSESSE_OVERVIEW
                    ? this.overviewData['overview']['sections']
                    : this.assessorOverviewData['overview']['sections'];
            this.totalSections =
                this.selectedTab === Tab.ASSESSE_OVERVIEW
                    ? this.overviewData['overview']['sections']
                    : this.assessorOverviewData['overview']['sections'];
            this.handleSubSectionsForAssessor(
                this.assessorOverviewData['overview']['sections']
            );
        }
        if (
            this.data &&
            this.data.response &&
            this.data.response['assesseeSubmitted']
        ) {
            this.disableSubmitButton = this.data.response['assesseeSubmitted'];
            this.generateSubmitButton(ButtonText.SUBMITTED);
        } else {
            this.generateSubmitButton(ButtonText.SUBMIT_ASSESSMENT);
        }
        if (
            this.data &&
            this.data.response &&
            this.data.response['assessorSubmitted']
        ) {
            this.disableActionButtons = this.data.response['assessorSubmitted'];
        }
        this.globalDataService.sideNavToggle.subscribe((data) => {
            if (data) {
                if (this.tabGroup) {
                    this.tabGroup.realignInkBar();
                }
            }
        });
        if (
            this.userDataCacheService.isAssessorView &&
            this.data &&
            this.data.response &&
            this.data.response['status']
        ) {
            if (
                this.restrictActionForStatus.includes(
                    this.data.response['status']
                )
            ) {
                this.disableActionButtons = true;
                this.disableSubmitButton = true;
            } else if (
                this.data.response['status'] ===
                    this.frameworkStatus.OPEN_ACTIONABLE_ITEM &&
                this.data.response['assesseOverview']
            ) {
                this.disableActionButtons = true;
                this.disableSubmitButton = true;
            } else if (
                this.data.response['status'] ===
                    this.frameworkStatus.OPEN_ACTIONABLE_ITEM &&
                !this.data.response['assesseOverview']
            ) {
                this.disableActionButtons = false;
                this.disableSubmitButton = false;
            }
            this.assessmentCacheService.assessmentStatus =
                this.data.response['status'];
        }
        this.generateButtons();
        this.generateDownloadButton();
        this.enableReportGenInput = {
            formName: 'Allow assesee to download report',
            submitButton: null,
            state: FormState.CREATE,
            fields: [
                {
                    fieldType: FilterType.CHECKBOX,
                    label: 'Summary Report',
                    extraClass: 'accent-checkbox',
                    fieldStyle: {
                        padding: '5px',
                    },
                    name: 'canDownloadSummaryReport',
                    placeholder: '',
                    value:
                        this.saveAssessmentReportAccessResponse
                            ?.canDownloadSummaryReport || false,
                },
                {
                    fieldType: FilterType.CHECKBOX,
                    label: 'Detailed Report',
                    extraClass: 'accent-checkbox',
                    name: 'canDownloadDeatiledReport',
                    fieldStyle: {
                        padding: '5px',
                    },
                    placeholder: '',
                    value:
                        this.saveAssessmentReportAccessResponse
                            ?.canDownloadDeatiledReport || false,
                },
            ],
        };
        this.metButtonInputs = [
            {
                buttonName: 'Not Applicable',
                buttonColorType: ButtonColorType.SECONDARY,
                buttonType: ButtonType.TEXT,
                buttonIconPrefix: this.downloadIcon,
                function: (buttonRef) => {
                    this.bulkUpdateStatus(
                        this.frameworkStatus.APPROVED,
                        MetStatuses.NOT_APPLICABLE
                    );
                },
                showLoader: true,
            },
            {
                buttonName: 'Exempted',
                buttonColorType: ButtonColorType.SECONDARY,
                buttonType: ButtonType.TEXT,
                buttonIconPrefix: this.downloadIcon,
                function: (buttonRef) => {
                    this.bulkUpdateStatus(
                        this.frameworkStatus.APPROVED,
                        MetStatuses.EXEMPTED
                    );
                },
                showLoader: true,
            },
            {
                buttonName: 'Ad Hoc',
                buttonColorType: ButtonColorType.SECONDARY,
                buttonType: ButtonType.TEXT,
                buttonIconPrefix: this.downloadIcon,
                function: (buttonRef) => {
                    this.bulkUpdateStatus(
                        this.frameworkStatus.APPROVED,
                        MetStatuses.AD_HOC
                    );
                },
                showLoader: true,
            },
            {
                buttonName: 'Repeatable',
                buttonColorType: ButtonColorType.SECONDARY,
                buttonType: ButtonType.TEXT,
                buttonIconPrefix: this.downloadIcon,
                function: (buttonRef) => {
                    this.bulkUpdateStatus(
                        this.frameworkStatus.APPROVED,
                        MetStatuses.REPEATABLE
                    );
                },
                showLoader: true,
            },
            {
                buttonName: 'Defined',
                buttonColorType: ButtonColorType.SECONDARY,
                buttonType: ButtonType.TEXT,
                buttonIconPrefix: this.downloadIcon,
                function: (buttonRef) => {
                    this.bulkUpdateStatus(
                        this.frameworkStatus.APPROVED,
                        MetStatuses.DEFINED
                    );
                },
                showLoader: true,
            },
            {
                buttonName: 'Optimised',
                buttonColorType: ButtonColorType.SECONDARY,
                buttonType: ButtonType.TEXT,
                buttonIconPrefix: this.downloadIcon,
                function: (buttonRef) => {
                    this.bulkUpdateStatus(
                        this.frameworkStatus.APPROVED,
                        MetStatuses.OPTIMIZED
                    );
                },
                showLoader: true,
            },
            {
                buttonName: 'Innovative',
                buttonColorType: ButtonColorType.SECONDARY,
                buttonType: ButtonType.TEXT,
                buttonIconPrefix: this.downloadIcon,
                function: (buttonRef) => {
                    this.bulkUpdateStatus(
                        this.frameworkStatus.APPROVED,
                        MetStatuses.INNOVATIVE
                    );
                },
                showLoader: true,
            },
        ];
    }
    openMenu() {
        this.menuTrigger.openMenu();
    }
    generateButtons() {
        this.rejectButtonGenInput = {
            buttonName: 'Reject',
            buttonColorType: ButtonColorType.WARN,
            buttonType: ButtonType.STROKED,
            function: (buttonRef) => {
                const modalData: IConfirmationModal = {
                    modalName: 'Reject Assessment',
                    confirmationMessage: `Please note that once you click "Confirm", the assessment will be Rejected, and you cannot modify any comments or statuses.`,
                    buttonText: 'Confirm',
                    buttonColorType: ButtonColorType.WARN,
                    hideCancelButton: true,
                    loaderOnExec: true,
                    function: (modalId: Symbol) => {
                        this.assessorSubmit(Status.REJECTED, buttonRef);
                        this.cdRef.detectChanges();
                    },
                    modalWidthVw: 40,
                    modalHeightVh: 30,
                };
                this.modalClose =
                    this.modalService.openConfirmationModal(modalData);
            },
            showLoader: true,
            disable: this.assessmentCacheService.assessmentView
                ? true
                : this.disableActionButtons,
            customClass: this.assessmentCacheService.assessmentView
                ? 'decrease-opacity'
                : this.disableActionButtons
                ? 'decrease-opacity'
                : 'opacity-1',
        };
        this.saveButtonGenInput = {
            buttonName: 'Save',
            buttonColorType: ButtonColorType.SECONDARY,
            buttonType: ButtonType.FLAT,
            function: (buttonRef) => {
                this.saveReportAccess(buttonRef);
            },
            showLoader: true,
        };
        this.approveButtonGenInput = {
            buttonName: this.isCSPScoring ? 'Complete Assessment' : 'Approve',
            buttonColorType: ButtonColorType.PRIMARY,
            buttonType: ButtonType.FLAT,
            function: (buttonRef) => {
                const modalData: IConfirmationModal = {
                    modalName: 'Approve Assessment',
                    confirmationMessage: `Please note that once you click "Confirm", the assessment will be ${
                        this.isCSPScoring ? 'Completed' : 'Approved'
                    }, and you cannot modify any comments or statuses.`,
                    buttonText: 'Confirm',
                    buttonColorType: ButtonColorType.PRIMARY,
                    hideCancelButton: true,
                    loaderOnExec: true,
                    function: (modalId: Symbol) => {
                        this.assessorSubmit(Status.APPROVED, buttonRef);
                        this.cdRef.detectChanges();
                    },
                    modalWidthVw: 40,
                    modalHeightVh: 30,
                };
                this.modalClose =
                    this.modalService.openConfirmationModal(modalData);
            },
            showLoader: true,
            disable: this.assessmentCacheService.assessmentView
                ? true
                : this.disableActionButtons,
            customClass: this.assessmentCacheService.assessmentView
                ? 'decrease-opacity'
                : this.disableActionButtons
                ? 'decrease-opacity'
                : 'opacity-1',
        };
        this.bulkActionButtonGenInput = {
            buttonName: 'Bulk Action',
            buttonColorType: ButtonColorType.PRIMARY,
            buttonType: ButtonType.FLAT,
            function: (buttonRef) => {
                this.trigger.openMenu();
            },
            showLoader: true,
            customClass: !this.enableBulkActionButton
                ? 'decrease-opacity'
                : 'opacity-1',
        };
    }
    generateSubmitButton(buttonName) {
        this.submitButtonGenInput = {
            buttonName: this.assessmentCacheService.assessmentView
                ? ButtonText.SUBMIT_ASSESSMENT
                : buttonName,
            buttonColorType: ButtonColorType.PRIMARY,
            buttonType: ButtonType.FLAT,
            function: (buttonRef) => {
                this.submitAssessment(buttonRef);
            },
            showLoader: true,
            disable: this.assessmentCacheService.assessmentView
                ? true
                : this.disableSubmitButton,
            customClass: this.assessmentCacheService.assessmentView
                ? 'decrease-opacity'
                : this.disableSubmitButton
                ? 'decrease-opacity'
                : 'opacity-1',
        };
    }
    handleSubSections(sections, SubSectionsName?, status?) {
        let totalChildSections = [];
        sections.forEach((section) => {
            if (section.level === 0) {
                if (section.attributes && section.attributes.length) {
                    section.attributes.forEach((subSection) => {
                        if (status) {
                            const attributes = subSection.attributes?.filter(
                                (att) => att.status === status
                            );
                            if (this.subSections.has(section.attributeName)) {
                                this.subSections
                                    .get(section.attributeName)
                                    .set(subSection.attributeName, attributes);
                            } else {
                                this.subSections.set(
                                    section.attributeName,
                                    new Map().set(
                                        subSection.attributeName,
                                        attributes
                                    )
                                );
                            }
                            if (
                                this.subSections.has(section.attributeName) &&
                                this.subSections
                                    .get(section.attributeName)
                                    .has(subSection.attributeName)
                            ) {
                                const section1 = this.subSections
                                    .get(section.attributeName)
                                    .get(subSection.attributeName);
                                if (section1 && section1.length) {
                                    this.handleSubSections(
                                        section1,
                                        subSection.attributeName,
                                        status
                                    );
                                }
                            }
                        } else if (!status) {
                            if (this.subSections.has(section.attributeName)) {
                                this.subSections
                                    .get(section.attributeName)
                                    .set(
                                        subSection.attributeName,
                                        subSection.attributes
                                    );
                            } else {
                                this.subSections.set(
                                    section.attributeName,
                                    new Map().set(
                                        subSection.attributeName,
                                        subSection.attributes
                                    )
                                );
                            }
                            if (
                                this.subSections.has(section.attributeName) &&
                                this.subSections
                                    .get(section.attributeName)
                                    .has(subSection.attributeName)
                            ) {
                                const section1 = this.subSections
                                    .get(section.attributeName)
                                    .get(subSection.attributeName);
                                if (section1) {
                                    this.handleSubSections(
                                        section1,
                                        subSection.attributeName
                                    );
                                }
                            }
                        }
                    });
                }
            } else if (status) {
                if (section.leafNode && section.status === status) {
                    totalChildSections.push(section);
                } else if (
                    !section.leafNode &&
                    section.attributes &&
                    section.attributes.length
                ) {
                    totalChildSections.push(section);
                    const result = this.handleSubSections(
                        section.attributes,
                        SubSectionsName,
                        status
                    );
                    totalChildSections = [...totalChildSections, ...result];
                }
            } else {
                if (section.leafNode) {
                    totalChildSections.push(section);
                } else if (
                    !section.leafNode &&
                    section.attributes &&
                    section.attributes.length
                ) {
                    totalChildSections.push(section);
                    const result = this.handleSubSections(
                        section.attributes,
                        SubSectionsName,
                        status
                    );
                    totalChildSections = [...totalChildSections, ...result];
                }
            }
            if (SubSectionsName) {
                this.subSectionsGroup.set(SubSectionsName, totalChildSections);
            }
        });

        return totalChildSections;
    }
    handleSubSectionsForAssessor(sections, SubSectionsName?, status?) {
        let totalChildSections = [];
        sections.forEach((section) => {
            if (section.level === 0) {
                if (section.attributes && section.attributes.length) {
                    section.attributes.forEach((subSection) => {
                        if (status) {
                            const attributes = subSection.attributes?.filter(
                                (att) => att.status === status
                            );
                            if (
                                this.subSectionsForAssessor.has(
                                    section.attributeName
                                )
                            ) {
                                this.subSectionsForAssessor
                                    .get(section.attributeName)
                                    .set(subSection.attributeName, attributes);
                            } else {
                                this.subSectionsForAssessor.set(
                                    section.attributeName,
                                    new Map().set(
                                        subSection.attributeName,
                                        attributes
                                    )
                                );
                            }
                            if (
                                this.subSectionsForAssessor.has(
                                    section.attributeName
                                ) &&
                                this.subSectionsForAssessor
                                    .get(section.attributeName)
                                    .has(subSection.attributeName)
                            ) {
                                const section1 = this.subSectionsForAssessor
                                    .get(section.attributeName)
                                    .get(subSection.attributeName);
                                if (section1 && section1.length) {
                                    this.handleSubSectionsForAssessor(
                                        section1,
                                        subSection.attributeName,
                                        status
                                    );
                                }
                            }
                        } else if (!status) {
                            if (
                                this.subSectionsForAssessor.has(
                                    section.attributeName
                                )
                            ) {
                                this.subSectionsForAssessor
                                    .get(section.attributeName)
                                    .set(
                                        subSection.attributeName,
                                        subSection.attributes
                                    );
                            } else {
                                this.subSectionsForAssessor.set(
                                    section.attributeName,
                                    new Map().set(
                                        subSection.attributeName,
                                        subSection.attributes
                                    )
                                );
                            }
                            if (
                                this.subSectionsForAssessor.has(
                                    section.attributeName
                                ) &&
                                this.subSectionsForAssessor
                                    .get(section.attributeName)
                                    .has(subSection.attributeName)
                            ) {
                                const section1 = this.subSectionsForAssessor
                                    .get(section.attributeName)
                                    .get(subSection.attributeName);
                                if (section1) {
                                    this.handleSubSectionsForAssessor(
                                        section1,
                                        subSection.attributeName
                                    );
                                }
                            }
                        }
                    });
                }
            } else if (status) {
                if (section.leafNode && section.status === status) {
                    totalChildSections.push(section);
                } else if (
                    !section.leafNode &&
                    section.attributes &&
                    section.attributes.length
                ) {
                    totalChildSections.push(section);
                    const result = this.handleSubSectionsForAssessor(
                        section.attributes,
                        SubSectionsName,
                        status
                    );
                    totalChildSections = [...totalChildSections, ...result];
                }
            } else {
                if (section.leafNode) {
                    totalChildSections.push(section);
                } else if (
                    !section.leafNode &&
                    section.attributes &&
                    section.attributes.length
                ) {
                    totalChildSections.push(section);
                    const result = this.handleSubSectionsForAssessor(
                        section.attributes,
                        SubSectionsName,
                        status
                    );
                    totalChildSections = [...totalChildSections, ...result];
                }
            }
            if (SubSectionsName) {
                this.subSectionsGroupForAssessor.set(
                    SubSectionsName,
                    totalChildSections
                );
            }
        });
        return totalChildSections;
    }
    navigateToSubSection(section) {
        this.globalDataService.fullPageLoader.next(true);
        const apiArgs: IHitApi = {
            url: section['nextUrl'],
            input: {},
            requestType: RequestType.GET,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN,
            },
            function: (data) => {
                this.globalDataService.routingData.next(data);
                this.assessmentCacheService.assessmentData = this.data;
            },
            errorFunction: (error) => {
                Helper.showErrorMessage(this.notificationService, error);
            },
        };

        new HitApi(
            apiArgs,
            this.widgetHttpService,
            this.widgetHttpService.ngZone
        ).hitApi();
    }
    submitAssessment(buttonRef) {
        if (buttonRef.loader || this.disableSubmitButton) {
            return;
        }
        const sectionStatus = this.checkLeafSectionStatus(this.sections);
        if (sectionStatus && sectionStatus.length) {
            this.notificationService.showSnackBar(
                'Section cannot be left blank, incomplete or marked for review.',
                true
            );
            return;
        }

        this.loader = true;
        buttonRef.loader = true;
        const apiArgs: IHitApi = {
            url: ApiUrls.SUBMIT_ASSESSMENT_API,
            input: {},
            requestType: RequestType.POST,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN,
            },
            function: () => {
                this.getOverviewData();
                buttonRef.loader = false;
            },
            errorFunction: (error) => {
                Helper.showErrorMessage(this.notificationService, error);
                this.loader = false;
                buttonRef.loader = false;
            },
        };
        new HitApi(
            apiArgs,
            this.widgetHttpService,
            this.widgetHttpService.ngZone
        ).hitApi();
    }
    getOverviewData() {
        const apiArgs: IHitApi = {
            url: ApiUrls.ASSESSE_LANDING_PAGE_API,
            input: {},
            requestType: RequestType.GET,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN,
            },
            function: (response) => {
                this.notificationService.showSnackBar(
                    'Assessment submitted successfully.'
                );
                this.data.response = response;
                if (
                    this.data &&
                    this.data.response &&
                    this.data.response['assessorOverview']
                ) {
                    this.assessorOverviewData = this.data.response['assessorOverview'];
                }
                const data = this.data;
                const url = window.location.pathname;
                const key = Helper.convertUrlToKey(url);
                this.widgetCacheService.store(key, data);
                this.disableSubmitButton = response['assesseeSubmitted'];
                this.disableActionButtons = response['assessorSubmitted'];
                this.generateSubmitButton(ButtonText.SUBMITTED);
                this.generateButtons();
                this.generateDownloadButton();
                this.loader = false;
                this.cdRef.detectChanges();
            },
            errorFunction: (error) => {
                this.generateSubmitButton(ButtonText.SUBMIT_ASSESSMENT);
                Helper.showErrorMessage(this.notificationService, error);
                this.loader = false;
                this.cdRef.detectChanges();
            },
        };
        apiArgs.url = apiArgs.url + '/' + this.data.response['id'];

        new HitApi(
            apiArgs,
            this.widgetHttpService,
            this.widgetHttpService.ngZone
        ).hitApi();
    }
    checkLeafSectionStatus(sections) {
        let incompleteSections = [];
        sections.forEach((section) => {
            if (
                section.leafNode &&
                this.restrictedStatus.includes(section.status)
            ) {
                incompleteSections.push(section.attributeName);
            } else if (
                !section.leafNode &&
                section.attributes &&
                section.attributes.length
            ) {
                const result = this.checkLeafSectionStatus(section.attributes);
                incompleteSections = [...incompleteSections, ...result];
            }
        });

        return incompleteSections;
    }
    assessorSubmit(status, buttonRef) {
        this.completedSections = [];
        if (
            this.statusLoader.get(Status.APPROVED) ||
            this.statusLoader.get(Status.REJECTED) ||
            buttonRef.loader
        ) {
            return;
        }
        if (this.disableActionButtons) {
            return;
        }
        const sectionStatus = this.checkLeafSectionStatusForAssessor(
            this.sections,
            this.isScoringAudit ? this.Status.EVALUATED : status
        );
        if (sectionStatus && sectionStatus.length) {
            this.notificationService.showSnackBar(
                `Assessment cannot be ${
                    this.isCSPScoring
                        ? 'Completed'
                        : status === this.Status.APPROVED
                        ? 'approved'
                        : 'rejected'
                } until all control points are marked as ${
                    this.isScoringAudit ? 'Submitted' : 'either met or not met'
                }`,
                true
            );
            this.modalService.closeModal(null, this.modalClose);
            return;
        }
        if (sectionStatus && sectionStatus.length ||
            (this.completedSections && this.completedSections.length === 0)) {
            this.notificationService.showSnackBar(
                `To ${
                    status === this.Status.APPROVED ? 'approve' : 'reject'
                } an assessment at least one control point should be marked as ${
                    status === this.Status.APPROVED ? 'met' : 'not met'
                }.`,
                true
            );
            this.modalService.closeModal(null, this.modalClose);
            return;
        }
        this.statusLoader.set(status, true);
        this.loader = true;
        buttonRef.loader = true;
        let statusToSend =
            status === Status.APPROVED
                ? FrameworkStatus.APPROVED
                : FrameworkStatus.REJECTED;
        if (this.isCSPScoring) {
            statusToSend = FrameworkStatus.COMPLETED;
        }
        const apiArgs: IHitApi = {
            url: ApiUrls.CHANGE_STATUS_OF_ASSESSMENT + `${statusToSend}`,
            intactUrl: `${ApiUrls.CHANGE_STATUS_OF_ASSESSMENT}{status}`,
            input: {},
            requestType: RequestType.POST,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN,
            },
            function: () => {
                this.statusLoader.clear();
                buttonRef.loader = false;
                this.getOverviewData();
                this.modalService.closeModal(null, this.modalClose);
                this.cdRef.detectChanges();
                this.enableBulkActionButton = false;
            },
            errorFunction: (error) => {
                this.statusLoader.clear();
                Helper.showErrorMessage(this.notificationService, error);
                this.modalService.closeModal(null, this.modalClose);
                this.loader = false;
                buttonRef.loader = false;
                this.cdRef.detectChanges();
            },
        };
        apiArgs.intactUrl = apiArgs.url;
        apiArgs.url = apiArgs.url.replace(
            '{assessment-id}',
            this.assessmentCacheService.assessmentId
        );
        apiArgs.url = apiArgs.url.replace(
            '{widget-id}',
            this.assessmentCacheService.widgetId
        );
        new HitApi(
            apiArgs,
            this.widgetHttpService,
            this.widgetHttpService.ngZone
        ).hitApi();
    }
    checkLeafSectionStatusForAssessor(sections, status) {
        let incompleteSections = [];
        sections.forEach((section) => {
            if (section.leafNode && section.status === status) {
                this.completedSections.push(section.attributeName);
            }
            if (
                section.leafNode &&
                ((!this.isScoringAudit &&
                    (!section.disabled) &&
                    section.status !== Status.APPROVED &&
                    section.status !== Status.REJECTED) ||
                    (this.isScoringAudit && section.status !== Status.EVALUATED))
            ) {
                if (!(this.isJuniperAudit && section.icon.startsWith('4'))) {
                    incompleteSections.push(section.attributeName);
                }
            } else if (
                !section.leafNode &&
                section.attributes &&
                section.attributes.length
            ) {
                const result = this.checkLeafSectionStatusForAssessor(
                    section.attributes,
                    status
                );
                incompleteSections = [...incompleteSections, ...result];
                this.completedSections = [...this.completedSections, ...result];
            }
        });

        return incompleteSections;
    }
    tabChanged(tab) {
        if (tab === this.Tab.ASSESSE_OVERVIEW) {
            this.sections = this.overviewData['overview']['sections'];
            this.totalSections = this.overviewData['overview']['sections'];
        } else if (this.assessorOverviewData) {
            this.sections = this.assessorOverviewData['overview']['sections'];
            this.totalSections =
                this.assessorOverviewData['overview']['sections'];
        }
        this.activeCard.clear();
        this.activeCard.set(this.Status.TOTAL_SECTION, true);
        this.selectedTab = tab;
        this.showSubSections.clear();
    }
    toggleSubSections(subSectionName) {
        if (
            this.showSubSections.has(subSectionName) &&
            this.showSubSections.get(subSectionName)
        ) {
            this.showSubSections.set(subSectionName, false);
        } else {
            this.showSubSections.set(subSectionName, true);
        }
    }
    selected(data, event, parentId?) {
        if (this.isChecked.has(this.selectedTab)) {
            if (!data['disabled']) {
                this.isChecked.get(this.selectedTab).set(data.id, {
                    checked: event.checked,
                    leafNode: data.leafNode,
                });
            }
        } else {
            if (!data['disabled']) {
                this.isChecked.set(
                    this.selectedTab,
                    new Map().set(data.id, {
                        checked: event.checked,
                        leafNode: data.leafNode,
                    })
                );
            }
        }
        if (data['attributes'] && data['attributes'].length) {
            data['attributes'].forEach((section) => {
                if (section['attributes'] && !section.disabled) {
                    if (this.isChecked.has(this.selectedTab)) {
                        this.isChecked.get(this.selectedTab).set(section.id, {
                            checked: event.checked,
                            leafNode: section.leafNode,
                        });
                    } else {
                        this.isChecked.set(
                            this.selectedTab,
                            new Map().set(section.id, {
                                checked: event.checked,
                                leafNode: section.leafNode,
                            })
                        );
                    }

                    this.selected(section, event);
                } else if (section.leafNode && !section.disabled) {
                    if (
                        this.isChecked.has(this.selectedTab) &&
                        this.isChecked.get(this.selectedTab)
                    ) {
                        this.isChecked.get(this.selectedTab).set(section.id, {
                            checked: event.checked,
                            leafNode: section.leafNode,
                        });
                    } else {
                        this.isChecked.set(
                            this.selectedTab,
                            new Map().set(section.id, {
                                checked: event.checked,
                                leafNode: section.leafNode,
                            })
                        );
                    }
                }
            });
        }
        if (parentId) {
            this.handleSelectedCards(parentId);
        }
        /**
         * Executes logic related to Juniper auditing.
         */
        if (this.isJuniperAudit) {
            const sectionNumber = data['icon'];
            if (event.checked) { // If event is checked
                if (sectionNumber === '3.1' || sectionNumber === '3.2') {
                    this.sectionIcons.set(sectionNumber, []);
                } else if (sectionNumber.includes('3.1.')) {
                    this.sectionIcons.has('3.1') && this.sectionIcons.get('3.1').indexOf(sectionNumber) === -1
                        ? this.sectionIcons.get('3.1').push(sectionNumber)
                        : this.sectionIcons.set('3.1', [sectionNumber]);
                } else if (sectionNumber.includes('3.2.')) {
                    this.sectionIcons.has('3.2') && this.sectionIcons.get('3.2').indexOf(sectionNumber) === -1
                        ? this.sectionIcons.get('3.2').push(sectionNumber)
                        : this.sectionIcons.set('3.2', [sectionNumber]);
                }
            } else { // If event is unchecked
                if (sectionNumber.includes('3.1.') && this.sectionIcons.has('3.1')
                    && this.sectionIcons.get('3.1').indexOf(sectionNumber) !== -1) {
                    this.sectionIcons.get('3.1').length === 0
                        ? this.sectionIcons.delete('3.1')
                        : this.sectionIcons.get('3.1').splice(this.sectionIcons.get('3.1').indexOf(sectionNumber), 1);
                } else if (sectionNumber.includes('3.2.') && this.sectionIcons.has('3.2')
                    && this.sectionIcons.get('3.2').indexOf(sectionNumber) !== -1) {
                    this.sectionIcons.get('3.2').length === 0
                        ? this.sectionIcons.delete('3.2')
                        : this.sectionIcons.get('3.2').splice(this.sectionIcons.get('3.2').indexOf(sectionNumber), 1);
                } else if (this.sectionIcons.has(sectionNumber)) {
                    this.sectionIcons.delete(sectionNumber);
                }
            }

            // If exactly two sections are selected, show an error message and disable a UI element
            if (this.sectionIcons && this.sectionIcons.size === 2) {
                Helper.showErrorMessage(
                    this.notificationService,
                    'Only one control point either 3.1 or 3.2 can be selected for submission.'
                );
                this.bulkActionButtonGenInput.disable = true;
                return;
            }
        }
        const selectedControlPoint = this.prepareInput().inputIds;
        if (
            selectedControlPoint &&
            selectedControlPoint.length &&
            !this.assessmentCacheService.assessmentView
        ) {
            if (
                this.selectedTab === Tab.ASSESSE_OVERVIEW &&
                !this.disableSubmitButton
            ) {
                this.enableBulkActionButton = true;
            } else if (
                this.selectedTab === Tab.ASSESSOR_OVERVIEW &&
                !this.disableActionButtons
            ) {
                this.enableBulkActionButton = true;
            }
        } else {
            this.enableBulkActionButton = false;
        }
        this.generateButtons();
        this.generateDownloadButton();
        if (this.disableSubmitButton) {
            this.generateSubmitButton(ButtonText.SUBMITTED);
        } else {
            this.generateSubmitButton(ButtonText.SUBMIT_ASSESSMENT);
        }
    }
    handleSelectedCards(parentId) {
        const parentData = this.totalSections.find(
            (section) => section['id'] === parentId
        );
        if (
            parentData &&
            parentData['attributes'] &&
            parentData['attributes'].length
        ) {
            const ids = this.getSelectedIds(parentData['attributes']);
            if (ids.length) {
                const selectedCard = [];
                ids.forEach((id) => {
                    if (
                        this.isChecked.has(this.selectedTab) &&
                        this.isChecked.get(this.selectedTab).get(id) &&
                        this.isChecked.get(this.selectedTab).get(id)['checked']
                    ) {
                        selectedCard.push(id);
                    }
                });
                if (selectedCard && !selectedCard.length) {
                    this.isChecked.get(this.selectedTab).set(parentId, {
                        checked: false,
                        leafNode: false,
                    });
                    this.cdRef.detectChanges();
                } else if (ids.length === selectedCard.length) {
                    this.isChecked.get(this.selectedTab).set(parentId, {
                        checked: true,
                        leafNode: false,
                    });
                    this.cdRef.detectChanges();
                } else if (ids.length !== selectedCard.length) {
                    this.isChecked.get(this.selectedTab).set(parentId, {
                        checked: false,
                        leafNode: false,
                    });
                    this.cdRef.detectChanges();
                }
            }
        }
    }
    getSelectedIds(sections) {
        let sectionIds = [];
        sections.forEach((section) => {
            if (section.leafNode) {
                sectionIds.push(section['id']);
            } else if (
                !section.leafNode &&
                section.attributes &&
                section.attributes.length
            ) {
                sectionIds.push(section['id']);
                const result = this.getSelectedIds(section.attributes);
                sectionIds = [...sectionIds, ...result];
            }
        });

        return sectionIds;
    }
    generateDownloadButton() {
        this.buttonInputs = [
            {
                buttonName: 'Summary Report',
                buttonColorType: ButtonColorType.SECONDARY,
                buttonType: ButtonType.TEXT,
                buttonIconPrefix: this.downloadIcon,
                function: (buttonRef) => {
                    this.widgetCreationService.downloadAssessmentReport(
                        buttonRef,
                        this.assessmentCacheService.assessmentId,
                        this.assessmentCacheService.assessmentName,
                        ReportType.SUMMARY_REPORT,
                        () => {
                            this.notificationService.showSnackBar(
                                'Report successfully downloaded'
                            );
                            buttonRef.loader = false;
                            this.cdRef.detectChanges();
                        },
                        (error) => {
                            Helper.showErrorMessage(
                                this.notificationService,
                                error,
                                'Error downloading report'
                            );
                            buttonRef.loader = false;
                            this.cdRef.detectChanges();
                        }
                    );
                },
                showLoader: true,
                disable:
                    this.enableSummaryReport ||
                    this.userDataCacheService.isAssessorView
                        ? false
                        : true,
                hoverText: !this.enableSummaryReport || !this.userDataCacheService.isAssessorView
                    ? 'Insufficient permission. Please contact Program Co-ordinator'
                    : ''
            },
            {
                buttonName: 'Detailed Report',
                buttonColorType: ButtonColorType.SECONDARY,
                buttonType: ButtonType.TEXT,
                buttonIcon: this.downloadIcon,
                function: (buttonRef) => {
                    this.widgetCreationService.downloadAssessmentReport(
                        buttonRef,
                        this.assessmentCacheService.assessmentId,
                        this.assessmentCacheService.assessmentName,
                        ReportType.DETAILED_REPORT,
                        () => {
                            this.notificationService.showSnackBar(
                                'Report successfully downloaded'
                            );
                            buttonRef.loader = false;
                            this.cdRef.detectChanges();
                        },
                        (error) => {
                            Helper.showErrorMessage(
                                this.notificationService,
                                error,
                                'Error downloading report'
                            );
                            buttonRef.loader = false;
                            this.cdRef.detectChanges();
                        }
                    );
                },
                showLoader: true,
                disable:
                    this.enableDetailedReport ||
                    this.userDataCacheService.isAssessorView
                        ? false
                        : true,
                hoverText: !this.enableDetailedReport || !this.userDataCacheService.isAssessorView
                    ? 'Insufficient permission. Please contact Program Co-ordinator'
                    : ''
            },
        ];
    }

    bulkUpdateStatus(status: FrameworkStatus, subSectionStatus?) {
        const sendStatus = (score?: number) => {
            const input = this.prepareInput();
            const apiArgs: IHitApi = {
                url: ApiUrls.FRAMEWORK_STATUS_API_V2,
                input: {
                    frameworkIds: [...input.frameworkIds],
                    ...(typeof score === 'number' ? { score } : {})
                },
                requestType: RequestType.POST,
                uniqueIdentity: Symbol(),
                config: {
                    authorization: AuthorizationType.BEARER_TOKEN,
                },
                function: () => {
                    this.notificationService.showSnackBar(
                        subSectionStatus
                            ? `Response has been submitted successfully`
                            : `Control Points Status Changed.`
                    );
                    const routeData =
                        this.globalDataService.sideMenuStepOne
                            .landingPageAttributes;
                    this.globalDataService.routingData.next(routeData);
                    this.cdRef.detectChanges();
                },
                errorFunction: (error) => {
                    Helper.showErrorMessage(this.notificationService, error);
                    this.cdRef.detectChanges();
                }
            };
            if (subSectionStatus) {
                apiArgs.input.assessmentSubAction = subSectionStatus;
            }
            apiArgs.intactUrl = apiArgs.url;
            apiArgs.url = apiArgs.url.replace('{action-enum}', status);
            new HitApi(
                apiArgs,
                this.widgetHttpService,
                this.widgetHttpService.ngZone
            ).hitApi();
        };

        if (
            this.assessmentCacheService.assessmentAuditType ===
                AssessmentAuditTypes.MATURITY &&
            (status === FrameworkStatus.APPROVED ||
                status === FrameworkStatus.REJECTED)
        ) {
            this.getScoreFromScoringModal(
                (modalId?: Symbol, formGroup?: FormGroup) => {
                    const scoreValue = formGroup?.get('score')?.value;
                    sendStatus(scoreValue && parseInt(scoreValue));
                    this.modalService.closeModal(null, modalId);
                    this.cdRef.detectChanges();
                },
                () => {
                    this.cdRef.detectChanges();
                }
            );
        } else {
            sendStatus();
        }
    }

    prepareInput() {
        const data = {
            inputIds: [],
            frameworkIds: [],
        };
        const inputIds = [];
        const frameworkIds = [];
        if (
            this.isChecked.has(this.selectedTab) &&
            this.isChecked.get(this.selectedTab) &&
            this.isChecked.get(this.selectedTab).keys()
        ) {
            const ids = Array.from(this.isChecked.get(this.selectedTab).keys());
            ids.forEach((id) => {
                if (
                    this.isChecked.has(this.selectedTab) &&
                    this.isChecked.get(this.selectedTab) &&
                    this.isChecked.get(this.selectedTab).has(id) &&
                    this.isChecked.get(this.selectedTab).get(id)['checked'] &&
                    this.isChecked.get(this.selectedTab).get(id)['leafNode']
                ) {
                    inputIds.push(id);
                }
            });
        }
        if (this.selectedTab !== 'Assessor Overview') {
            const arrayAssessee = this.getFrameworkIds(
                this.overviewData.overview,
                inputIds,
                frameworkIds
            );
            data.frameworkIds = arrayAssessee;
        } else {
            const arrayAssessor = this.getFrameworkIds(
                this.assessorOverviewData.overview,
                inputIds,
                frameworkIds
            );
            data.frameworkIds = arrayAssessor;
        }
        data.inputIds = inputIds;
        return data;
    }
    getFrameworkIds(data, inputIds?, frameworkIds?) {
        if (data.sections) {
            data.sections.forEach((section) => {
                if (section.framework) {
                    if (inputIds.includes(section.id)) {
                        frameworkIds.push(section.framework.id);
                    }
                } else {
                    this.getFrameworkIds(section, inputIds, frameworkIds);
                }
            });
        } else if (data.attributes) {
            data.attributes.forEach((section) => {
                if (section.framework) {
                    if (inputIds.includes(section.id)) {
                        frameworkIds.push(section.framework.id);
                    }
                } else {
                    this.getFrameworkIds(section, inputIds, frameworkIds);
                }
            });
        }
        return frameworkIds;
    }
    bulkUploadEvidence() {
        const modalData: IModalData = {
            modalName: 'Upload Evidence',
            modalIcon: null,
            sourceId: Symbol(),
            modalType: ModalType.MIDDLE,
            modalAutoHeight: true,
            modalWidthVw: 70,
            noStepPadding: true,
            hideSteps: true,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: UploadEvidenceModalComponent,
                        payload: {
                            data: this.prepareInput(),
                        },
                    },
                    stepName: 'Step 1',
                },
            ],
        };
        this.modalService.openModal(modalData);
    }
    saveReportAccess(btnRef) {
        const apiArgs: IHitApi = {
            url: ApiUrls.SAVE_ACCESS_REPORT_ASSESSMENT,
            input: this.enableReportFormRef.value,
            requestType: RequestType.POST,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN,
            },
            function: (data) => {
                this.notificationService.showSnackBar(
                    'Changes added successfully'
                );
            },
            errorFunction: (error) => {},
        };
        apiArgs.url = apiArgs.url.replace(
            '{assessment-id}',
            this.assessmentCacheService.assessmentId
        );
        new HitApi(
            apiArgs,
            this.widgetHttpService,
            this.widgetHttpService.ngZone
        ).hitApi();
    }
    checkIfBulkDataExist() {
        if (
            this.isChecked.has(this.selectedTab) &&
            this.isChecked.get(this.selectedTab) &&
            this.isChecked.get(this.selectedTab).keys()
        ) {
        }
    }
    filterCards(cardStatus, cardControlPoints?: number) {
        if (cardControlPoints !== undefined && cardControlPoints < 1) {
            this.notificationService.showSnackBar(
                'No control points found.',
                true
            );
            return;
        }
        this.activeCard.clear();
        this.activeCard.set(cardStatus, true);
        if (this.selectedTab === Tab.ASSESSE_OVERVIEW) {
            this.totalSections = this.overviewData['overview']['sections'];
        } else {
            this.totalSections =
                this.assessorOverviewData['overview']['sections'];
        }
        this.sections = Helper.cloneDeep(this.totalSections);
        if (cardStatus === Status.TOTAL_SECTION) {
            this.handleSubSections(this.sections);
            this.handleSubSectionsForAssessor(this.sections);
            return;
        }
        const sections = Helper.cloneDeep(this.sections);
        this.totalSections.forEach((section) => {
            this.sections = this.filterCardData(sections, cardStatus);
        });
        this.handleSubSections(this.sections, null, cardStatus);
        this.handleSubSectionsForAssessor(this.sections, null, cardStatus);
    }
    filterCardData(sections, status) {
        const totalSections = sections;
        totalSections.map((section, index) => {
            if (section.leafNode && section.status !== status) {
                totalSections.splice(index, 1);
            } else if (
                !section.leafNode &&
                section.attributes &&
                section.attributes.length
            ) {
                const att = this.filterCardData(section.attributes, status);
                if (att && !att.length) {
                    const index = totalSections.findIndex(
                        (att) => att.attributeName === section.attributeName
                    );
                    if (index !== -1) {
                        totalSections.splice(index, 1);
                    }
                }
            }
        });
        return totalSections;
    }

    private getScoreFromScoringModal(onSuccess: Function, onCancel: Function) {
        const scoringAssessmentModalData: IScoringAssessmentModal = {
            descriptionText: `Please enter the score for the selected control point(s)`,
            buttonText: 'Submit',
            buttonColorType: ButtonColorType.PRIMARY,
            loaderOnExec: true,
            fontSize: 'fs150'
        };
        const modalData: IModalData = {
            modalName: 'Scores',
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-trash'
            },
            sourceId: Symbol(),
            modalType: ModalType.MIDDLE,
            modalHeightVh: 37,
            modalWidthVw: 50,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: ScoringAssessmentModalComponent,
                        payload: {
                            data: {
                                function: onSuccess,
                                params: scoringAssessmentModalData
                            }
                        }
                    },
                    stepName: 'Confirmation'
                }
            ],
            closeCallback: onCancel
        };
        this.modalService.openModal(modalData);
    }
}

enum Tab {
    ASSESSE_OVERVIEW = 'Assessee Overview',
    ASSESSOR_OVERVIEW = 'Assessor Overview',
    ASSESSMENT_REPORT = 'Assessment Report',
}
export enum Status {
    READY_FOR_ASSESSMENT = 'Completed',
    MARKED_FOR_REVIEW = 'Marked For Review',
    INCOMPLETE = 'Incomplete',
    BLANK = 'Blank',
    APPROVED = 'Met',
    REJECTED = 'Not Met',
    ISSUED = 'ISSUED',
    IN_PROGRESS = 'IN_PROGRESS',
    MARKED_FOR_REWORK = 'Open Action Item',
    TOTAL_SECTION = 'total section',
    TOTAL_SCORE_SECTION = 'TOTAL_SCORE_SECTION',
    EVALUATED = 'Evaluated',
    NOT_EVALUATED = 'Not Evaluated'
}
enum ButtonText {
    SUBMIT_ASSESSMENT = 'Submit Assessment',
    SUBMITTED = 'Submitted',
}
export enum ReportType {
    DETAILED_REPORT = 'DetailedReport',
    SUMMARY_REPORT = 'SummaryReport',
}
interface SaveAssessmentReportAccessResponseType {
    canDownloadSummaryReport: boolean;
    canDownloadDeatiledReport: boolean;
    tags: unknown[];
}
